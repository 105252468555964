
.layout-topbar-blue .layout-topbar {
    color: black;
    background-color: white;
}

.layout-topbar-blue .layout-topbar .layout-topbar-left {
    background-color: #eeeeee;
}

.layout-topbar-blue .layout-topbar .layout-topbar-left .layout-menu-button {
    color: #212121;
    background-color: #219EBC;
  }

.layout-topbar-blue .layout-topbar .layout-topbar-left .layout-menu-button:hover {
    background-color: rgba(33, 158, 188, 0.7);
    transition: background-color 0.2s;
}

.layout-topbar-blue .layout-topbar button {
    color: var(--surface-700);
}
